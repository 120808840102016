import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../Reducers';
import rootSaga from '../Saga';
 
const logger = createLogger();
const saga = createSagaMiddleware();
 
const store = createStore(
  rootReducer,
  undefined,
  applyMiddleware(saga, logger)
);
 
saga.run(rootSaga);
 
export default store;