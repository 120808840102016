import { createMuiTheme } from '@material-ui/core/styles';

/* CSS HEX 
--imperial-red: #e63946ff;
--honeydew: #f1faeeff;
--powder-blue: #a8dadcff;
--celadon-blue: #457b9dff;
--prussian-blue: #1d3557ff;
*/

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1d3557ff',
    },
    secondary: {
      main: '#457b9dff',
    },
    error : {
      main: '#e63946ff'
    }
  },
});

export default theme;